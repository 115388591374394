import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import AccountMenu from '../AccountMenu'
import { SignOut } from '../Auth'
import { TacDogLogo } from '../../icons'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, backgroundColor: '#4d4845' },
  appbar: { backgroundColor: '#4d4845', width: '80%', margin: 'auto' },
  title: { flexGrow: 1, marginLeft: 120, fontSize: '1.2em' },
  logo: {
    paddingTop: '13px',
    paddingBottom: '13px',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  buttons: {
    textAlign: 'right',
    verticalAlign: 'top',
    width: '100%',
    height: '100%',
  }
}))

export default function ButtonAppBar() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appbar}>
        <Toolbar>
          <a href="https://tac.dog/">
            <TacDogLogo className={classes.logo} />
          </a>
          <div className={classes.buttons}>
            <AccountMenu />
            <SignOut />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}