import React, {useState} from 'react'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { ControlledTextField } from '../Inputs/ControlledTextField'

const GitHostField = ({record, field}) => {
  const [ state, setState ] = useState(record.integrations[field.id] || '')

  return <TextField
    value={state}
    label={field.name}
    variant="outlined"
    style={{ width: '50%', marginBottom: '1em' }}
    onChange={event => {
      record.integrations[field.id] = event.target.value
      setState(event.target.value)
    }}
  />
}

const APIKeyField = ({ record, field }) => {
  const [ state, setState ] = useState(_.get(record.integrations, field.id, ''))

  return <TextField
    value={state}
    label={field.name}
    variant="outlined"
    style={{ width: '30em' }}
    onChange={event => {
      record.integrations[field.id] = event.target.value
      setState(event.target.value)
    }}
  />
}

const GitRepoSettings = ({integration, record}) => {
  const fields = [
    { id: 'repo', name: 'Repository' },
    { id: 'branch', name: 'Branch' },
    { id: 'tac', name: 'TaC file path' },
  ]

  return (
    <Grid container direction="row" alignItems="center" spacing={3}>
      { fields.map(field => (
        <Grid item xs key={`griditem-${field.id}`}>
          <ControlledTextField record={record.integrations} field={field} />
        </Grid>
      ) ) }
    </Grid>
  )
}

export { APIKeyField, GitHostField, GitRepoSettings as default }