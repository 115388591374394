import React from 'react';

// Material components
import { ReactComponent as Logo } from './GitHub-Mark-White.svg';

export default function GitHub({width, height}) {
  return (
    <Logo width={width} height={height} />
  );
}
