import React from 'react'
import { trackPromise } from 'react-promise-tracker'
import { withSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'

import { ControlledTextFieldWithAccept } from '../../Inputs/ControlledTextField'

import dataProvider from '../../../providers/dataProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1em',
    padding: '1em',
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const DataDogSettings = ({record, enqueueSnackbar}) => {
  const classes = useStyles()

  const onSave = (record) => {
    return trackPromise(
      dataProvider.update('account', { data: record })
        .then(() => {
          enqueueSnackbar("Integration updated.", {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
        .catch(error => {
          console.log("ERROR", error.message)
          enqueueSnackbar(error.message, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
    )
  }

  return (
    <div className={classes.root}>
      <ControlledTextFieldWithAccept
        record={record}
        field={{
          id: 'integrations.datadog.api_key',
          name: "API key"
        }}
        onSave={onSave}
      />
    </div>
  )
}

export default withSnackbar(DataDogSettings)
