import React, {useState} from 'react'
import { useLocation, Link } from "react-router-dom"
import clsx from 'clsx'

import { green, lightBlue } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'

import { GitHubLogo } from '../../../icons'
import dataProvider from '../../../providers/dataProvider'

const useStyles = makeStyles((theme) => ({
  paper: { padding: '2em' },
  root: { display: 'flex', alignItems: 'center' },
  wrapper: { margin: theme.spacing(1), position: 'relative' },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: lightBlue[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  dialogNotify: { width: '30em', margin: '1em' }
}))

const IntegrationAuthorize = props => {
  console.log("IntegrationAuthorize()")
  const useQuery = () => new URLSearchParams(useLocation().search)
  const classes = useStyles()
  const query = useQuery()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const buttonClassname = clsx({ [classes.buttonSuccess]: success })
  const integration_id = 'github'
  const code = query.get('code')
  const installation_id = query.get('installation_id')
  const setup_action = query.get('setup_action')

  const handleButtonClick = () => {
    dataProvider.integrationAuthorize({ integration_id, installation_id, code, setup_action }).then(
      setSuccess(true)
    ).catch(error => {
      console.log("authorize:error", error)
      setSuccess(false)
    }).finally(() => setLoading(false))
  }

  const AskForAccess = () => (
    <div className={classes.dialogNotify}>
      <Typography variant="h6">
        <p>
          Authorize access to your GitHub account?
        </p>
      </Typography>
      <Typography variant="subtitle2">
        <p>
          We will only access files within the path
          specified in your GitHib repository settings.
        </p>
      </Typography>
    </div>
  )

  const NotifyDone = () => (
    <>
      <Typography variant="h6">
        You're all set!
      </Typography>
      <Typography variant="subtitle2">
        <div className={classes.dialogNotify}>
          <p>
            You can adjust the branch and path in the&nbsp;
            <Link to="/integration" replace>
              Integrations control panel
            </Link>
          </p>
          <p>
            If you want to manage the integration, including controlling
            which repositories will be included, use the&nbsp;
            <a href="https://github.com/settings/installations/9005616">
              application settings
            </a>
            &nbsp;page on GitHub.
          </p>
        </div>
      </Typography>
    </>
  )

  return (
    <Paper elevation={1} className={classes.paper}>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab
            aria-label="authorize"
            color="primary"
            className={buttonClassname}
          >
            {
              success
              ? <CheckIcon />
              : <SvgIcon><GitHubLogo width={24} height={24} /></SvgIcon>
            }
          </Fab>
          {loading && <CircularProgress size={68} className={classes.fabProgress} />}
        </div>
        <div className={classes.wrapper}>
          <Grid container direction='column' alignItems='center' spacing={3}>
            <Grid item>
              {success ? <NotifyDone /> : <AskForAccess /> }
            </Grid>
            <Grid item>
              {(success || loading)
                ? <div />
                : <Button
                    variant="contained"
                    color="secondary"
                    className={buttonClassname}
                    onClick={handleButtonClick}
                  >
                    Authorize
                  </Button>
              }
            </Grid>
          </Grid>
        </div>
      </div>
    </Paper>
  )
}

export default IntegrationAuthorize
