
import React from 'react'

import Switch from '@material-ui/core/Switch'

const BooleanInput = props => {
  const {checked, onChange, ...rest} = props
  const [state, setState] = React.useState({ checked })

  React.useEffect(() => { setState({ checked }) }, [checked])

  const handleChange = (e) => {
    setState({ checked: e.target.checked })
  }

  return (
    <Switch
      checked = {state.checked}
      onChange = {(e) => { handleChange(e); onChange(e) }}
      inputProps = {{ 'aria-label': 'primary checkbox' }}
      {...rest}
    />
  )
}

export default BooleanInput
