import GitHubSettings from './GitHub/Settings'
import DataDogSettings from './DataDog/Settings'
import GitRepoSettings, { GitHostField, APIKeyField } from './GitRepoSettings'
import { ControlledTextField } from '../Inputs/ControlledTextField'

import {
  BitBucketLogo,
  DatadogLogo,
  GitHubLogo,
  GitLogo,
  PagerDutyLogo,
  SlackLogo,
  TeamsLogo,
  WebhooksLogo,
} from '../../icons'

const IntegrationPlugins = () => ({
  bitbucket: {
    name: 'BitBucket',
    trademark: true,
    fields: [
      { id: 'repo_settings', name: "Repo settings", control: GitRepoSettings },
      { id: 'deploy_keys', name: 'Deploy keys', control: ControlledTextField },
    ],
    style: { backgroundColor: '#DEEBFF', 'color': '#0052cc' },
    logo: BitBucketLogo,
    subtext: `Import checks from a BitBucket repository.`,
    tags: [ 'import', 'tac', 'cloud' ],
    promo: "Available Q3 2020"
  },
  git: {
    name: 'Git',
    trademark: true,
    fields: [
      { id: 'host', name: 'Host', control: GitHostField },
      { id: 'repo_settings', name: "Repo settings", control: GitRepoSettings },
      { id: 'deploy_keys', name: 'Deploy keys', control: ControlledTextField },
    ],
    style: { backgroundColor: '#3E2C00', 'color': '#F1502F' },
    logo: GitLogo,
    subtext: `Import checks from a private Git repository.`,
    tags: [ 'import', 'tac' ],
    promo: "Available Q1 2021"
  },
  github: {
    name: 'GitHub',
    trademark: true,
    fields: [
      { id: 'repositories', name: 'Repositories', control: GitHubSettings },
    ],
    style: { backgroundColor: '#444444', 'color': '#ffffff' },
    logo: GitHubLogo,
    subtext: "Import checks from a GitHub repository.",
    tags: [ 'import', 'tac', 'cloud' ],
  },
  webhooks: {
    name: 'Webhooks',
    trademark: false,
    fields: [
      { id: 'url', name: 'URL to TaC file', control: ControlledTextField },
      { id: 'username', name: 'Username', control: ControlledTextField },
      { id: 'password', name: 'Password', control: ControlledTextField },
    ],
    style: { backgroundColor: '#90caf9', 'color': '#ffffff' },
    logo: WebhooksLogo,
    subtext: "Import checks via generic HTTP callbacks.",
    tags: [ 'import', 'tac' ],
    promo: "Available Q3 2020"
  },
  datadog: {
    name: 'Datadog',
    trademark: true,
    fields: [
      { id: 'api_key', name: 'API key', control: DataDogSettings },
    ],
    style: { backgroundColor: '#632CA6', 'color': '#ffffff' },
    logo: DatadogLogo,
    subtext: "Stream telemetry and events to Datadog.",
    tags: [ 'export', 'telemetry', 'cloud' ],
    promo: false
  },
  pagerduty: {
    name: 'PagerDuty',
    trademark: true,
    fields: [
      { id: 'integration_key', name: 'Integration key', control: APIKeyField },
    ],
    style: { backgroundColor: '#11c24b', 'color': '#ffffff' },
    logo: PagerDutyLogo,
    subtext: "Send alerts to PagerDuty.",
    tags: [ 'export', 'alerts', 'cloud' ],
    promo: false
  },
  slack: {
    name: 'Slack',
    trademark: true,
    fields: [
      { id: 'api_key', name: 'API key', control: ControlledTextField },
      { id: 'channel', name: 'Channel', control: ControlledTextField },
    ],
    style: { backgroundColor: '#ffffff', 'color': '#1264a3' },
    logo: SlackLogo,
    subtext: "Send alerts to a Slack channel.",
    tags: [ 'export', 'alerts', 'cloud' ],
    promo: "Available Q4 2020"
  },
  teams: {
    name: 'Microsoft Teams',
    trademark: true,
    fields: [
      { id: 'api_key', name: 'API key', control: ControlledTextField },
      { id: 'channel', name: 'Channel', control: ControlledTextField },
    ],
    style: { backgroundColor: '#efefef', 'color': '#6264a7' },
    logo: TeamsLogo,
    subtext: "Send alerts to a Teams channel.",
    tags: [ 'export', 'alerts', 'cloud' ],
    promo: "Available Q4 2020"
  },
})

export default IntegrationPlugins
