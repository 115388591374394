import React, {useRef, useState} from 'react'
import { trackPromise } from 'react-promise-tracker'
import gravatar from 'gravatar'
import { withSnackbar } from 'notistack'
import Clone from 'rfdc'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Avatar from '@material-ui/core/Avatar'
import BackspaceIcon from '@material-ui/icons/Backspace'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import DialogContent from '@material-ui/core/DialogContent'
import EditIcon from '@material-ui/icons/Edit'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Paper from '@material-ui/core/Paper'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import SearchIcon from '@material-ui/icons/Search'
import SortIcon from '@material-ui/icons/Sort'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { blue, grey } from '@material-ui/core/colors'

import { Icon as Iconify } from "@iconify/react"
import shieldEdit from '@iconify/icons-mdi/shield-edit'
import shieldSearch from '@iconify/icons-mdi/shield-search'
import shieldOff from '@iconify/icons-mdi/shield-off'
import fingerprintIcon from '@iconify/icons-mdi/fingerprint'
import fingerprintOff from '@iconify/icons-mdi/fingerprint-off'

import MaterialTable from 'material-table'

import { ControlledTextField } from '../Inputs/ControlledTextField'
import dataProvider from '../../providers/dataProvider'

const clone = Clone()

const useStyles = makeStyles((theme) => ({
  root: {},
  acls: { margin: 'auto' },
  title: { fontSize: 20 },
  subtitle: { fontSize: 12, padding: "0 0 1em 0" },
  aclFormControl: { margin: 0 },
  aclFormLabel: { marginBottom: '0.5em' },
  aclGrid: {},
  icon: { color: blue[500] }
}))

const iconProps = {
  style: {
    color: grey[500]
  }
}

const tableIcons = {
  Add: AddIcon,
  Check: CheckIcon,
  Clear: CloseIcon,
  Delete: () => <DeleteIcon {...iconProps} />,
  DetailPanel: NavigateNextIcon,
  Edit: () => <EditIcon {...iconProps} />,
  Export: SaveAltIcon,
  FirstPage: FirstPageIcon,
  LastPage: LastPageIcon,
  NextPage: NavigateNextIcon,
  PreviousPage: NavigateBeforeIcon,
  ResetSearch: BackspaceIcon,
  Search: SearchIcon,
  SortArrow: SortIcon,
}

const default_acls = {
  "account": { "create": false, "delete": false, "edit": false, "view": false },
  "integrations": { "edit": false, "view": false },
  "user": { "create": false, "delete": false, "edit": false, "view": false },
  "keys": { "create": false, "delete": false, "view": false }
}

const columns = [
  { title: '', field: 'id',
    editable: 'never',
    render: row => {
      if (row) {
        const userpic = gravatar.url(row.id, { s: 32, r: 'pg' })
        return (
          <Tooltip title={row.id || ''} aria-label={row.id}>
            <Box>
              <Avatar
                alt={row.id}
                src={userpic}
              />
            </Box>
          </Tooltip>
        )
      } else {
        return <span />
      }
    },
    headerStyle: { width: 48 },
    cellStyle: { width: 48 }
  },
  { title: 'Username', field: 'id', searchable: true,
    editable: 'onAdd',
    cellStyle: { minWidth: '10em' }
  },
  { title: 'Full name', field: 'full_name', searchable: true,
    cellStyle: { minWidth: '15em' }
  }
]

const TableContainer = (props) => (
  <Paper elevation={0} {...props}>
    {props.children}
  </Paper>
)

const AclForm = ({record, field, title, perms, text}) => {
  record.acls[field] = { ...default_acls[field], ...record.acls[field] || {} }

  const classes = useStyles()
  const [state, setState] = useState(record.acls[field])

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
    if (!record.acls[field]) record.acls[field] = {}
    record.acls[field][event.target.name] = event.target.checked
  }

  return (
    <FormControl component="fieldset" className={classes.aclFormControl} style={{ maxWidth: '15em', margin: 'auto' }}>
      <FormLabel component="legend" className={classes.aclFormLabel}>{title}</FormLabel>
      <FormGroup>
        {
          perms.map(p => (
            <FormControlLabel
              key={`${p}`}
              label={p}
              control={
                <Checkbox
                  checked={state[p]}
                  onChange={handleChange}
                  name={p}
                />
              }
            />
          ))
        }
      </FormGroup>
      <FormHelperText>{text}</FormHelperText>
    </FormControl>
  )
}

const UserAclsForm = withSnackbar(({record, enqueueSnackbar}) => {
  const handleSaveKey = ({record}) => {
    trackPromise(
      dataProvider.update('user', { data: record })
      .then(({data}) => (
        enqueueSnackbar("User updated.", {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        })
      ))
      .catch(error => {
        console.log("ERROR", error.message)
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        })
      })
    )
  }

  return (
    <div style={{ margin: 'auto', flexGrow: 1 }}>
      <Paper elevation={0} style={{ padding: '1em 1em 1em 4em' }}>
        <Grid container
          direction="row"
          justify="space-around"
          alignItems="flex-start"
          style={{ width: '100%' }}
        >
          <Grid item xs={1} sm={2} md={3} lg={4}>
            <AclForm
              record={record}
              field='account'
              title="Account"
              perms={['view', 'edit', 'create', 'delete']}
              text="Allow this user to manage account settings."
            />
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={4}>
            <AclForm
              record={record}
              field='user'
              title="Users"
              perms={['view', 'edit', 'create', 'delete']}
              text="Allow this user to manage other users."
            />
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={4}>
            <AclForm
              record={record}
              field='integrations'
              title="Integrations"
              perms={['view', 'edit' ]}
              text="Allow this user to manage integrations."
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ width: '100%', textAlign: 'right' }}>
              <Button onClick={(event) => handleSaveKey({ event, record })}>
                Apply
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
})

const UserGrid = withSnackbar(({ids, data, account, enqueueSnackbar, ...props}) => {
  const tableRef = useRef()
  const users = Object.keys(data).map(key => data[key])
  const [ state, setState ] = useState({ users: users })

  const handleSaveKey = ({row}) => {
    trackPromise(
      dataProvider.update('user', { data: row })
      .then(({data}) => (
        enqueueSnackbar("User updated.", {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        })
      ))
      .catch(error => {
        console.log("ERROR", error.message)
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        })
      })
    )
  }

  const editHandlers = {
    onRowAdd: newData => trackPromise(
      dataProvider.create('user', { data: newData })
        .then(({data}) => {
          var newUsers = clone(state.users)
          newUsers.push({ ...newData, ...data })
          setState({ users: newUsers })
          tableRef.current && tableRef.current.onQueryChange()
          enqueueSnackbar("User created.", {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
        .catch(error => {
          console.log("ERROR", error.message)
          enqueueSnackbar(error.message, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
    ),

    onRowDelete: oldData => trackPromise(
      dataProvider.delete('user', { data: { id: oldData.id }})
        .then(({data}) => {
          var newUsers = clone(state.users)
          newUsers.splice(oldData.tableData.id, 1)
          setState({ users: newUsers })
          tableRef.current && tableRef.current.onQueryChange()
          enqueueSnackbar("User deleted.", {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
        .catch(error => {
          console.log("ERROR", error.message)
          enqueueSnackbar(error.message, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
    ),

    onRowUpdate: (newData, oldData) => trackPromise(
      dataProvider.update('user', { data: newData })
        .then(({data}) => {
          var newUsers = clone(state.users)
          newUsers[oldData.tableData.id] = data.data
          setState({ users: newUsers })
          enqueueSnackbar(`User updated.`, {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
        .catch(error => {
          console.log("ERROR", error.message)
          enqueueSnackbar(error.message, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
    )
  }

  const searchable = columns.filter(row => row.searchable).map(row => row.field)

  return (
    <DialogContent style={{ padding: 0 }}>
      <MaterialTable
        isLoading={!state.users}
        title="Users"
        icons={tableIcons}
        components={{Container: TableContainer}}
        tableRef={tableRef}
        columns={columns}
        data={query => new Promise((resolve, reject) => {
          const { search } = query
          const s = search.toLowerCase()

          trackPromise(
            dataProvider.getList('user')
            .then(({data}) => {
              setState({ users: data })
              const result = data.filter(
                row => searchable.filter(
                  field => row[field] && row[field].toLowerCase().includes(s)
                ).length > 0
              )
              resolve ({ data: result, totalCount: result.length, page: 0 })
            })
          )
        })}
        editable={editHandlers}
        options={{
          emptyRowsWhenPaging: false,
          exportButton: false,
          actionsColumnIndex: -1,
          detailPanelColumnAlignment: 'left',
          filtering: false,
          search: true,
          paging: false,
        }}
        localization={{
          body: {
            addTooltip: 'New user',
            emptyDataSourceMessage: 'Click the "+" icon to create a new user.',
            editRow: {
              deleteText: "Delete this user?",
              saveTooltip: "Ok", // used for both update and delete, so must be generic
            }
          },
        }}
        detailPanel={[
          row => {
            if (!row.acls) row.acls = []
            const hasWrite = Object.keys(row.acls).filter(k => row.acls[k].edit || row.acls[k].create || row.acls[k].delete).length > 0
            const hasRead = Object.keys(row.acls).filter(k => row.acls[k].view).length > 0
            const aclIcon = hasWrite ? shieldEdit : hasRead ? shieldSearch : shieldOff
            const aclIconColor = hasWrite || hasRead ? blue[500] : grey[500]
            const aclIconTip = hasWrite ? "User can update some resources" : hasRead ? "User can read some resources" : "User has no access"

            return {
              tooltip: aclIconTip,
              icon: () => <Iconify icon={aclIcon} color={aclIconColor} />,
              render: () => {
                return (
                  <Box style={{ width: '97%', margin: '1em' }} >
                    <UserAclsForm record={row} />
                  </Box>
                )
              }
            }
          },
          row => {
            const keyTip = row.signing_key ? "User can sign code" : "User cannot sign code"
            const keyIcon = row.signing_key ? fingerprintIcon : fingerprintOff
            const keyIconColor = row.signing_key ? blue[500] : grey[500]

            return {
              tooltip: keyTip,
              icon: () => <Iconify icon={keyIcon} color={keyIconColor} />,
              render: () => {
                return (
                  <Box style={{ padding: '1em', width: '70%', margin: 'auto' }} >
                    <form>
                      <ControlledTextField
                        record={row}
                        field={{ id: 'signing_key', name: 'Signing key' }}
                        multiline
                        rowsMax={10}
                        InputProps={{
                          style: {
                            fontFamily: 'Monospace',
                            fontSize: '10pt',
                            overflowWrap: 'anywhere',
                          },
                          spellCheck: 'false'
                        }}
                        style={{
                          width: '100%'
                        }}
                      />
                      <div style={{ width: '100%', textAlign: 'right', marginTop: '1em' }}>
                        <Button onClick={(event) => handleSaveKey({ event, row })}>
                          Apply
                        </Button>
                      </div>
                    </form>
                  </Box>
                )
              }
            }
          }
        ]}
      />
    </DialogContent>
  )
})

UserGrid.defaultProps = {
  data: {},
  ids: [],
}

const UserList = ({dispatch, ...props}) => {
  return <UserGrid style={{ minWidth: 600 }} />
}

export default UserList
