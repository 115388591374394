import React, { useState, useEffect } from 'react'
import { Switch, Route, Link as RouterLink } from "react-router-dom"
import { trackPromise } from 'react-promise-tracker'
import _ from 'lodash'
import validator from 'validator'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import LockIcon from '@material-ui/icons/Lock'
import { amber, grey } from '@material-ui/core/colors'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import AccountEdit from '../Account/Edit'
import IntegrationGrid from '../Integrations/IntegrationGrid'
import IntegrationAuthorize from '../Integrations/GitHub/IntegrationAuthorize'
import UserList from '../Users/UserList'
import ActionDialog from './ActionDialog'
import dashboardItems from './dashboardItems'
import dataProvider from '../../providers/dataProvider'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '1.5rem',
    padding: 0,
    margin: 'auto',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  menu_div: { width: '80%' },
  item_div: { padding: '1em 2em 0 2em' },
  menu_text: { color: '#ffa726', fontSize: '0.8em', height: '69px', paddingTop: '1em' },
  locked_text: { color: '#999999' },
  tile_title: { fontWeight: 'bold' },
  tile_text: { color: 'rgba(255,255,255,0.5)' },
  settingsIcon: {
    fontSize: '32px',
    padding: '6px',
    color: '#efebe9',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    border: '0.5px solid #dddddd',
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,0.2)',
    '&:hover': { color: amber[600] },
  },
  lockIcon: {
    fontSize: '32px',
    padding: '6px',
    border: '0.5px solid #dddddd',
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,0.1)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  settingsButton: {
    float: 'left'
  }
}))

const routes = [
  { path: '/account', component: AccountEdit },
  { path: '/user', component: UserList },
  { path: '/integration/github/authorize', component: IntegrationAuthorize },
  { path: '/integration', component: IntegrationGrid },
]

const Link = ({ to, ...otherProps }) => (
  validator.isURL(to.pathname)
    ? <a href={to.pathname} target="_blank" rel="noopener noreferrer" {...otherProps} />
    : <RouterLink to={to} {...otherProps} />
)

const appRoutes = () => {
  return (
    <Switch>
      { routes.map((route, key) => <Route exact key={key} {...route} />) }
    </Switch>
  )
}

const menuText = (text, className, props) => {
  if (isWidthUp('lg', props.width))
    return <span className={className}>{text}</span>
  return <></>
}

const MiniMenu = ({ onMenuClick, ...props }) => {
  const classes = useStyles()
  const [state, setState] = useState({ record: {} })

  useEffect(() => {
    trackPromise(
      dataProvider.get_current_user().then(username =>
        dataProvider.get_current_account_id().then(account_id =>
          dataProvider.getOne('user', { id: username })
          .then(({data}) => setState({ record: data }))
        )
      )
    )
  }, [])

  if (state.record.acls === undefined) {
    return <div />
  }

  return (
    <div className={classes.root}>
      <div className={classes.menu_div}>
        <Grid container>
        {
          dashboardItems.map((tile) => {
            let ToolIcon = tile.icon
            let unlocked = _.get(state.record.acls, tile.acl, true)
            let menu_text = unlocked ? tile.text : 'Access is restricted.'

            return (
              <Grid item key={tile.title} xs={6} sm={3}>
                <div className={classes.item_div}>
                  {
                  unlocked
                  ?
                    <Link to={tile.to}>
                      <IconButton aria-label="Settings" className={classes.settingsButton}>
                        <ToolIcon className={classes.settingsIcon} />
                      </IconButton>
                    </Link>
                  :
                    <IconButton aria-label="Settings" disabled={true} className={classes.settingsButton}>
                      <LockIcon className={classes.lockIcon} />
                    </IconButton>
                  }
                  <div className={unlocked ? classes.menu_text : classes.locked_text}>
                    <span className={classes.tile_title}>{tile.title}</span><br />
                    { menuText(menu_text, classes.tile_text, props) }
                  </div>
                </div>
              </Grid>
            )
          })
        }
        </Grid>
      </div>

      <ActionDialog>
        {appRoutes()}
      </ActionDialog>
    </div>
  )
}

export default withWidth()(MiniMenu)