import React from 'react'
import { Auth } from 'aws-amplify'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import { grey, orange } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

const useStyles = makeStyles((theme) => ({
  button: {
    color: grey[50],
    border: '0.6px solid white',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    borderRadius: '20px'
  }
}))

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    '&:hover': {
      backgroundColor: orange[700],
    }
  }
}))(Button)

const SignOut = () => {
  const classes = useStyles()

  const handleSignOut = (e) => {
    Auth.signOut()
  }

  return (
    <ColorButton
      startIcon={<ExitToAppIcon />}
      onClick = {handleSignOut}
      variant = "contained"
      color = "secondary"
      className = {classes.button}
    >
      Sign Out
    </ColorButton>
  )
}

export default SignOut
