import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withSnackbar } from 'notistack'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import CodeIcon from '@material-ui/icons/Code'
import BackupIcon from '@material-ui/icons/Backup'
import MemoryIcon from '@material-ui/icons/Memory'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import StepConnector from '@material-ui/core/StepConnector'
import { blue, red, grey, amber } from '@material-ui/core/colors'

import dataProvider from '../../providers/dataProvider'

const ColorlibConnector = withStyles({
  alternativeLabel: { top: 22 },
  active: { '& $line': { backgroundColor: blue[300] } },
  completed: { '& $line': { backgroundColor: grey[300] } },
  line: {
    height: 8,
    border: 0,
    backgroundColor: grey[400],
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: grey[400],
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.5px solid #e0e0e0'
  },
  active: {
    backgroundColor: grey[800],
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: blue[300],
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  error: {
    backgroundColor: red[600],
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }
})

const ColorlibStepIcon = (props) => {
  const classes = useColorlibStepIconStyles()
  const { active, completed, error } = props
  const icons = {
    1: <CodeIcon />,
    2: <CloudDownloadIcon />,
    3: <MemoryIcon />,
    4: <BackupIcon />,
  }

  return (
    <div className={clsx(classes.root, {[classes.active]: active, [classes.completed]: completed, [classes.error]: error })}>
      { error ? <ErrorOutlineIcon /> : icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
  error: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
  root: { width: '80%', margin: 'auto' },
  stepper: { borderRadius: '0 0 20px 20px', backgroundColor: 'rgba(0,0,0,0.15)' },
  github_link: {
    color: blue[400],
    textDecoration: 'none',
    '&:hover': { color: amber[600] }
  },
  github_header_link: {
    color: grey[800],
    textDecoration: 'none',
    '&:hover': { color: amber[600] }
  },
  step_summary: {
    fontSize: '0.9em',
    color: '#222222',
    borderRadius: '16px',
    margin: 'auto'
  },
  repoName: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: grey[100],
  }
}))

const ProgressIndicator = ({repo, repos, enqueueSnackbar}) => {
  const status = repos.current[repo]
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(-1)
  const [failedSteps, setFailedSteps] = React.useState([])
  const steps = ['update', 'pull', 'compile', 'deploy']

  const getSteps = () => {
    return steps
  }

  const isStepFailed = (step) => {
    return failedSteps.includes(step)
  }

  const summary_colors = {
    'update':  [grey[300],  grey[800]],
    'pull':    [grey[300],  grey[800]],
    'compile': [amber[600], grey[800]],
    'deploy':  [grey[300],  grey[800]],
  }

  useEffect(() => {
    setActiveStep(Object.keys(status).length)
  }, [status])

  const redeploy = async (repo) => {
    dataProvider.integration_github_redeploy({ data: repo })
    .then(
      ({data}) => {
        enqueueSnackbar(`${repo.full_name} has been queued for redeployment.`, {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        })
      }
    )
    .catch(error => {
      console.log("ERROR", error.message)
      enqueueSnackbar(error.message, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
      })
    })
  }

  return (
    <div className={classes.root}>
      <div style={{ paddingLeft: '2em', backgroundColor: 'rgba(0,0,0,0.15)', borderRadius: '20px 20px 0 0' }}>
        {/* <IconButton
          fontSize='large'
          style={{ padding: '4px 10px 4px 10px' }}
          onClick={() => { console.log({ full_name: repo, ref: 'master', path: '/tacdog', id: status.id }) }}>
          <CloudUploadTwoToneIcon fontSize='large' />
        </IconButton> */}
        <a href={`https://github.com/${repo}`}
          className={classes.github_header_link}
          style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '32px' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {repo}
        </a>
      </div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        className={classes.stepper}>
        {
          getSteps().map((label, index) => {
            let labelProps = { error: isStepFailed(index) }
            let message_text = _.get(status, `${label}.message.text`, '')
            let message_url = _.get(status, `${label}.message.url`, '')
            let timestamp = _.get(status, `${label}.timestamp`, null)
            let date = timestamp ? new Date(timestamp).toLocaleString() : ''
            let [summary_fg, summary_bg] = _.get(summary_colors, label,'inherit')

            return (
              <Step key={label}>
                <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                  <div style={{ color: grey[900] }}>{label}</div>
                  <div style={{ fontSize: '0.9em', color: grey[800] }}>{date}</div>
                  <div className={classes.step_summary} style={{ backgroundColor: summary_bg, color: summary_fg }}>
                  {
                    message_url
                    ? <a href={message_url} className={classes.github_link} target="_blank" rel="noopener noreferrer">{message_text}</a>
                    : <span style={{ color: summary_fg }}>{message_text}</span>
                  }
                  </div>
                </StepLabel>
              </Step>
            )
        })}
      </Stepper>
    </div>
  )
}

export default withSnackbar(ProgressIndicator)