import React, { useState, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'
import Cookies from 'universal-cookie'
import _ from 'lodash'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PopupState from 'material-ui-popup-state'
import { bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import { grey, blue } from '@material-ui/core/colors'
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import dataProvider from '../../providers/dataProvider'

const cookies = new Cookies()

const useStyles = makeStyles((theme) => ({
  button: { color: grey[50], marginRight: '1em', borderRadius: '20px' },
}))

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[300]),
    backgroundColor: blue[300],
    '&:hover': {
      backgroundColor: blue[200],
    },
    border: '0.6px solid white',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }
}))(Button)

const AccountMenu = props => {
  const classes = useStyles()
  const [accounts, setAccounts] = useState([])
  const [selectedItem, setSelectedItem] = useState(0)

  const handleSelection = (index, account_id) => {
    setSelectedItem(index)
    cookies.set('selected_account', account_id, { path: '/' })
  }

  useEffect(() => {
    trackPromise(
      dataProvider.getList('account').then(
        ({data}) => {
          let selected = Math.max(_.findIndex(data, { id: cookies.get('selected_account') }), 0)
          setSelectedItem(selected)
          setAccounts(data)
          cookies.set('selected_account', data[selected].id, { path: '/' })
        }
      )
    )
  }, [])

  return (
    <PopupState variant="popover" popupId="account-menu">
      {popupState => (
        <React.Fragment>
          <ColorButton {...bindTrigger(popupState)}
            startIcon={<GroupWorkIcon />}
            variant = "contained"
            color = "secondary"
            className = {classes.button}
          >
            {accounts.length > selectedItem ? accounts[selectedItem].name : 'Select account'}
          </ColorButton>
          <Menu {...bindMenu(popupState)}>
            {
              accounts.map((acct, i) => (
                <MenuItem
                  key = {acct.id}
                  selected = { i === selectedItem }
                  onClick = { () => {
                    handleSelection(i, acct.id)
                    popupState.close()
                  }}
                >
                  {acct.name}
                </MenuItem>
              ))
            }
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

export default AccountMenu