import React, {useState} from 'react'
import _ from 'lodash'

import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import InputAdornment from '@material-ui/core/InputAdornment'

const ControlledTextField = ({record, field, ...props}) => {
  const [ state, setState ] = useState({ value: _.get(record, field.id, '') })

  return <TextField
    value={state.value}
    label={field.name}
    onChange={event => {
      _.set(record, field.id, event.target.value)
      setState({ value: event.target.value })
    }}
    {...props}
  />
}

const ControlledTextFieldWithAccept = ({record, field, onSave, ...props}) => {
  const [ state, setState ] = useState({ value: _.get(record, field.id, '') })

  const onChange = event => {
    setState({ value: event.target.value })
  }

  const onAccept = () => {
    _.set(record, field.id, state.value)
    onSave(record)
  }

  const isDirty = (state, record) => {
    return state.value !== _.get(record, field.id)
  }

  return (
    <Grid container direction='row' alignItems="stretch">
      <Grid item xs={12}>
        <TextField
          value={state.value}
          label={field.name}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onAccept}>
                  { isDirty(state, record) ? <CheckIcon /> : <span style={{width: '1em'}} /> }
                </IconButton>
              </InputAdornment>
            )
          }}
          {...props}
        />
      </Grid>
    </Grid>
  )
}

export { ControlledTextFieldWithAccept, ControlledTextField }
