import React from 'react'

// import MainMenu from '../components/MainMenu'
import MiniMenu from '../components/MainMenu/miniMenu'
import DeployProgress from '../components/DeployProgress/index.js'


const Layout = props => {
  return (
    <div style={{ width: '100%' }}>
      <MiniMenu />
      <DeployProgress />
    </div>
  )
}

export default Layout
