import React, { useRef, useState, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import Clone from 'rfdc'
import _ from 'lodash'

import AddIcon from '@material-ui/icons/Add'
import BackspaceIcon from '@material-ui/icons/Backspace'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Paper from '@material-ui/core/Paper'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import SearchIcon from '@material-ui/icons/Search'
import SortIcon from '@material-ui/icons/Sort'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { grey } from '@material-ui/core/colors'

import dataProvider from '../../../providers/dataProvider'

const integration_id = 'github'

const clone = Clone()
const iconProps = { style: { color: grey[500] } }
const tableIcons = {
  Add: AddIcon,
  Check: CheckIcon,
  Clear: CloseIcon,
  Delete: () => <DeleteIcon {...iconProps} />,
  DetailPanel: VpnKeyIcon,
  Edit: () => <EditIcon {...iconProps} />,
  Export: SaveAltIcon,
  FirstPage: FirstPageIcon,
  LastPage: LastPageIcon,
  NextPage: NavigateNextIcon,
  PreviousPage: NavigateBeforeIcon,
  ResetSearch: BackspaceIcon,
  Search: SearchIcon,
  SortArrow: SortIcon,
}

const TableContainer = (props) => (
  <Paper elevation={0} {...props}>
    {props.children}
  </Paper>
)

const GitHubSettings = ({ record, enqueueSnackbar }) => {
  const tableRef = useRef()
  const installation_id = _.get(record, 'integrations.github.installation_id')
  const [state, setState ] = useState({ record: { installation_id: installation_id, repos: {} }})
  const columns = [
    { title: 'Name', field: 'full_name', searchable: true, editable: 'never' },
    { title: 'Ref', field: 'ref', searchable: true },
    { title: 'Path', field: 'path', searchable: true },
  ]
  const searchable = columns
    .filter(row => row.searchable)
    .map(row => row.field)

  useEffect(() => {
    trackPromise(
      dataProvider.getOne('integration', {
        id: integration_id,
        qs: { installation_id: state.record.installation_id }
      })
      .then(
        ({data}) => {
          setState({ record: data })
          tableRef.current && tableRef.current.onQueryChange()
        }
      )
    )
  }, [state.record.installation_id])

  Object.filter = (obj, predicate) =>
    Object.fromEntries(Object.entries(obj).filter(predicate))

  const redeploy = async (repo) => {
    dataProvider.integration_github_redeploy({ data: repo })
    .then(
      ({data}) => {
        enqueueSnackbar(`${repo.full_name} has been queued for redeployment.`, {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        })
      }
    )
    .catch(error => {
      console.log("ERROR", error.message)
      enqueueSnackbar(error.message, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
      })
    })
  }

  const editHandlers = {
    onRowUpdate: (newData, oldData) => {
      state.record.repos[oldData.id] = { ...oldData, ...newData }
      let out_record = clone(state.record)

      for (let [key, value] of Object.entries(out_record.repos)) {
        if (Object.keys(value).includes('tableData'))
          delete out_record.repos[key]['tableData']
      }

      const params = {
        data: out_record,
        qs: { installation_id: out_record.installation_id }
      }

      return trackPromise(
        dataProvider.update('integration', params)
          .then(() => {
            enqueueSnackbar("Repository updated.", {
              variant: 'success',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
            })
          })
          .catch(error => {
            console.log("ERROR", error.message)
            enqueueSnackbar(error.message, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
            })
          })
      )
    }
  }

  return (
    <MaterialTable
      components={{ Container: TableContainer }}
      tableRef={tableRef}
      columns={columns}
      data={query => new Promise((resolve, reject) => {
        const { search } = query
        const s = search.toLowerCase()
        const repos = Object.values(state.record.repos)
        const result = s.length > 0
          ? repos.filter(
            repo => searchable.filter(
              search => repo[search].includes(s)
            ).length > 0
          )
          : repos

        resolve({ data: result, totalCount: result.length, page: 0 })
      })}
      title="Repositories"
      icons={tableIcons}
      options={{
        emptyRowsWhenPaging: false,
        exportButton: false,
        actionsColumnIndex: -1,
        filtering: false,
        search: true,
        paging: false,
      }}
      actions={[
        {
          icon: () => <CloudUploadIcon {...iconProps} />,
          tooltip: 'Redeploy',
          onClick: (event, rowData) => { redeploy(rowData) }
        }
      ]}
      localization={{
        body: {
          addTooltip: 'New repo',
          emptyDataSourceMessage: 'This integration must be configured on GitHub.',
          editRow: {
            deleteText: "Delete this repository?",
            saveTooltip: "Ok", // used for both update and delete, so must be generic
          }
        },
      }}
      editable={editHandlers}
    />
  )
}

export default withSnackbar(GitHubSettings)