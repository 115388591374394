import React, { useState, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { withSnackbar } from 'notistack'
import _ from 'lodash'
import Cookies from 'universal-cookie'

import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'

import IntegrationPlugins from './integrations'
import QuickSettingsButton from './QuickSettings'
import { BooleanInput } from '../Inputs'
import dataProvider from '../../providers/dataProvider'

const cookies = new Cookies()

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  enabledForm: { margin: 0, padding: 0 },
  gridList: { borderRadiusTop: '20px' },
  tile: { textAlign: 'center', minWidth: '300px', padding: '2em' },
  tilebar: { textAlign: 'left' },
  icon: { color: '#ffffff' },
  toggleIcon: { padding: 0, margin: 0 },
  promo: { paddingLeft: '1em' },
}))

const toggleIntegration = ({ integration_id, record, enqueueSnackbar }) => {
  // trackPromise(
    dataProvider.update("account", { data: record })
      .then(({data}) => {
        const enabled = _.get(data, `data.integrations.${integration_id}.enabled`, false)
        enqueueSnackbar(`Integration ${integration_id} ${enabled ? "enabled" : "disabled"}.`, {
          variant: enabled ? 'info' : 'warning',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        })
      })
      .catch(error => {
        console.log("ERROR", error.message)
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        })
      })
  // )
}

const IntegrationToggle = ({record, integrations, integration_id, enqueueSnackbar}) => {
  const checked = _.get(record, `integrations.${integration_id}.enabled`, false)

  return (
    <BooleanInput
      color = "secondary"
      label = ""
      checked = {checked}
      onChange = { event => {
        if (!_.has(record, `integrations.${integration_id}`))
          record['integrations'][integration_id] = {}
        record['integrations'][integration_id].enabled = event.target.checked
        toggleIntegration({
          integration_id: integration_id,
          record: record,
          enqueueSnackbar: enqueueSnackbar
        })
      }}
    />
  )
}

const IntegrationGrid = ({ enqueueSnackbar }) => {
  const classes = useStyles()
  const integrations = IntegrationPlugins()
  const [state, setState] = useState({ record: {} })
  const account_id = cookies.get('selected_account')

  useEffect(() => {
    trackPromise(
      dataProvider.getOne('account', { id: account_id })
        .then(({data}) => setState({ record: data }))
    )
  }, [account_id])

  return (
    <DialogContent style={{ padding: 0 }}>
      <div className={classes.root}>
        <GridList className={classes.gridList} rows={2} cols={4} spacing={0} cellHeight={330}>
        {
          Object.keys(integrations).map(integration_id => {
            var title = <span>{ integrations[integration_id]['name'] }{ integrations[integration_id].trademark ? <sup>&trade;</sup> : <></> }</span>
            var TileIcon = integrations[integration_id].logo

            return (
              <GridListTile key={integration_id} rows={1} cols={1} style={integrations[integration_id].style} className={classes.tile}>
                <div style={{ paddingTop: 90 }}>
                  <TileIcon width={128} height={128} />
                </div>
                <GridListTileBar
                  title=""
                  titlePosition='top'
                  actionPosition={integrations[integration_id].promo ? 'left' : 'right'}
                  style={{ backgroundColor: 'rgba(255,255,255,0.6)' }}
                  actionIcon={
                    integrations[integration_id].promo
                    ? <Typography variant="button" component="span" className={classes.promo}>
                        {integrations[integration_id].promo}
                      </Typography>
                    : <IntegrationToggle
                        record={state.record}
                        integrations={integrations}
                        integration_id={integration_id}
                        enqueueSnackbar={enqueueSnackbar}
                        className={classes.toggleIcon}
                      />
                  }
                />,
                <GridListTileBar
                  title={title}
                  subtitle={integrations[integration_id]['subtext']}
                  actionIcon={
                    integrations[integration_id].promo
                    ? <div style={{ width: '2em' }} />
                    : <QuickSettingsButton record={state.record || {}} integration={integrations[integration_id]} />
                  }
                  className={classes.tilebar}
                />
              </GridListTile>
            )
          })
        }
        </GridList>
      </div>
    </DialogContent>
  )
}

export default withSnackbar(IntegrationGrid)