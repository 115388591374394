import { API, Auth } from "aws-amplify"
import Cookies from 'universal-cookie'
import _ from 'lodash'

const cookies = new Cookies()

const api_headers = async () => {
  const session = await Auth.currentSession()
  const headers = {
    'Authorization': session.idToken.jwtToken,
    'X-TacDog-Account': cookies.get('selected_account')
  }

  return headers
}

export default {
  getList: async (resource, params) => {
    const endpoint = `/${resource}`
    const options = {
      headers: await api_headers(),
      queryStringParameters:_.get(params,'qs', {})
    }
    const data = await API.get(resource, endpoint, options)

    return Promise.resolve({
      data: data,
      total: data.length
    })
  },

  getOne: async (resource, params) => {
    const endpoint = `/${resource}/${params.id}`
    const options = {
      headers: await api_headers(),
      queryStringParameters:_.get(params,'qs', {})
    }
    const data = await API.get(resource, endpoint, options)

    return Promise.resolve({ data: data })
  },

  getMany: async (resource, params) => {
    console.log("getMany ", resource, params)

    return Promise.reject(params)
  },

  getManyReference: async (resource, params) => {
    const endpoint = `/${resource}`
    const options = {
      headers: await api_headers(),
      queryStringParameters:_.get(params, 'qs', {})
    }
    const data = await API.get(resource, endpoint, options)

    return Promise.resolve({
      data: data,
      total: data.length
    })
  },

  update: async (resource, params) => {
    const endpoint = `/${resource}/${params.data.id}`
    const options = {
      headers: await api_headers(),
      body: params.data,
      queryStringParameters:_.get(params,  'qs', {})
    }
    const data = await API.put(resource, endpoint, options)

    return Promise.resolve({ data: data })
  },

  updateMany: async (resource, params) => {
    console.log("updateMany")

    return Promise.reject()
  },

  create: async (resource, params) => {
    const endpoint = `/${resource}`
    const options = {
      headers: await api_headers(),
      body: params.data,
      queryStringParameters:_.get(params, 'qs', {})
    }
    const data = await API.post(resource, endpoint, options)

    return Promise.resolve({ data: data })
  },

  delete: async (resource, params) => {
    const endpoint = `/${resource}/${params.data.id}`
    const options = { headers: await api_headers() }
    const data = await API.del(resource, endpoint, options)

    return Promise.resolve({ data: data })
  },

  deleteMany: async (resource, params) => {
    console.log("deleteMany", resource, params)

    return Promise().reject()
  },

  // specialized enpoints
  integrationAuthorize: async (params) => {
    console.log("dataProvider:integrationAuthorize()")
    const { integration_id, installation_id, code, setup_action } = params
    const resource = 'integration'
    const endpoint = `/${resource}/${integration_id}/authorize`
    const options = {
      headers: await api_headers(),
      queryStringParameters: {
        code: code,
        installation_id: installation_id,
        setup_action: setup_action
      }
    }
    const data = await API.get(resource, endpoint, options)
    console.log("dataProvider:integrationAuthorize()", endpoint)

    return Promise.resolve({ data: data })
  },

  deployStatus: async (params) => {
    const resource = 'integration'
    const endpoint = `/${resource}/deploy/status`
    const options = {
      headers: await api_headers()
    }
    const data = await API.get(resource, endpoint, options)
    return Promise.resolve({ data: data })
  },

  integration_github_redeploy: async (params) => {
    const resource = 'integration'
    const endpoint = `/${resource}/github/redeploy`
    const options = {
      headers: await api_headers(),
      body: params.data,
    }

    const data = await API.post(resource, endpoint, options)

    return Promise.resolve({ data: data })
  },

  get_current_user: async (params) => {
    const userInfo = await Auth.currentUserInfo()
    return Promise.resolve(userInfo.attributes.email)
  },

  get_current_account_id: async (params) => {
    return Promise.resolve(
      cookies.get('selected_account')
    )
  }
}