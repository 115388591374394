import React from 'react';

// Material components
import { ReactComponent as Logo } from './webhooks.svg';

export default function Webhooks({width, height}) {
  return (
    <Logo width={width} height={height} />
  );
}
