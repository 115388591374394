import React, {useState, useRef} from 'react'

import ProgressIndicator from './indicator'
import useInterval from '../../hooks/useInterval'
import dataProvider from '../../providers/dataProvider'


// --------------------------------------------------
// DeployProgress
// --------------------------------------------------
const DeployProgress = () => {
  const repos = useRef({ current: {} })
  const [state, setState] = useState({ repos: {} })

  useInterval(async () => {
    const response = await dataProvider.deployStatus()
    repos.current = response.data
    setState({ repos: response.data })
  }, 3000)

  return Object.keys(repos.current).map(key =>
    <div style={{ marginTop: '1.5em' }} key={key}>
      <ProgressIndicator repo={key} repos={repos} />
    </div>
  )
}

export default DeployProgress