import React from 'react';

// Material components
import { ReactComponent as Logo } from './dd_logo_v_white.svg';

export default function Datadog({width, height}) {
  return (
    <Logo width={width} height={height} />
  );
}
