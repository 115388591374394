import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import config from './config'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

const endpoint = {
  endpoint: config.api_gateway.URL,
  region: config.api_gateway.REGION
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userIdentityId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    // identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      { name: "account", ...endpoint },
      { name: "user", ...endpoint },
      { name: "integration", ...endpoint },
      { name: "integration_generate_keypair", ...endpoint },
    ]
  }
})

const federated = {
  google_client_id: config.cognito.GOOGLE_CLIENT_ID,
}

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider maxSnack={3}>
      <App federated={federated} />
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
