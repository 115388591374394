import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { trackPromise } from 'react-promise-tracker'
import { withSnackbar } from 'notistack'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import CheckIcon from '@material-ui/icons/Check'

import dataProvider from '../../providers/dataProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0em',
    padding: '1em 1em 2em 1em',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'rgb(255,255,255)'
  },
  field: {
    minWidth: '60%'
  }
}))

const AccountEdit = ({enqueueSnackbar}) => {
  const classes = useStyles()
  const cookies = new Cookies()
  const account_id = cookies.get('selected_account')
  const [state, setState] = useState({ record: { name: '' } })
  const [oldValue, setOldValue] = useState('')

  const onSave = () => {
    return trackPromise(
      dataProvider.update('account', { data: state.record })
        .then(() => {
          setOldValue(state.record.name)
          enqueueSnackbar("Account updated.", {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
        .catch(error => {
          console.log("ERROR", error.message)
          enqueueSnackbar(error.message, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
    )
  }

  useEffect(() => {
    trackPromise(
      dataProvider.getOne('account', { id: account_id })
        .then(({data}) => {
          setState({ record: data })
          setOldValue(data.name)
        })
    )
  }, [account_id])

  return (
    <DialogContent style={{ padding: 0 }}>
      <Paper elevation={0} className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Account details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField required fullWidth
              value={state.record.name}
              label="Account name"
              className={classes.field}
              onChange={event => {
                setState({ record: { ...state.record, ...{ name: event.target.value } } })
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onSave}>
                      { oldValue !== state.record.name ? <CheckIcon /> : <span style={{width: '1em'}} /> }
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </DialogContent>
  )
}

export default withSnackbar(AccountEdit)