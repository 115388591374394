import React from 'react'
import { withRouter } from "react-router-dom"

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import CancelIcon from '@material-ui/icons/Cancel'
import Button from '@material-ui/core/Button'

const ActionDialog = withRouter(props => {
  const {children, location} = props
  const showDialog = location.pathname && location.pathname !== '/'
  const handleCloseClick = (e) => {
    if (e) e.preventDefault()
    props.history.push('/')
  }

  return (
    <Dialog
      disableBackdropClick={true}
      maxWidth="lg"
      scroll="body"
      open={showDialog}
      onClose={handleCloseClick}
      hideBackdrop={false}
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: '#e7e7e7',
          padding: '0',
          borderRadius: '20px'
        }
      }}
      BackdropProps={{
        style: { background: 'rgba(0,0,0,0.5)' }
      }}
    >
      { children }

      <DialogActions>
        <Button onClick={handleCloseClick}>
          <CancelIcon />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ActionDialog
