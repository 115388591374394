import React, { useState, Fragment } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconCancel from '@material-ui/icons/Cancel'
import SettingsIcon from '@material-ui/icons/Settings'

import { withSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  root: { margin: 0, padding: 0 },
  dialogRoot: { margin: 0, padding: 0, minWidth: '500px' },
  dialogTitle: { borderRadiusTop: "20px", paddingLeft: '16px', paddingTop: '8px' },
  dialogContent: { root: { margin: 0, padding: 0 }},
  dialogActions: { padding: "15px" },
  settingsIcon: { fontSize: 32, color: '#ffffff', padding: 0 }
}))

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent)

const QuickSettingsButton = withSnackbar(({ enqueueSnackbar, ...props }) => {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const { record, integration } = props

  Object.filter = (obj, predicate) =>
    Object.fromEntries(Object.entries(obj).filter(predicate))

  if (!record) return <div />

  return (
    <div className={classes.root}>
       <Button onClick={() => setShowDialog(true)}>
        <SettingsIcon className={classes.settingsIcon} />
      </Button>
      <Dialog
        disableBackdropClick={true}
        maxWidth="md"
        scroll="body"
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label={`${props.integration.name} settings`}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: '#e7e7e7',
            padding: '0',
            borderRadius: '20px'
          }
        }}
      >
        <DialogTitle classes={{ root: classes.dialogRoot }}>
          <div className={classes.dialogTitle} style={integration.style}>
            <Grid container direction="row">
              <Grid item>
                { (() => {
                  var IntegrationLogo = integration.logo
                  return <IntegrationLogo width={48} height={48} />
                })() }
              </Grid>
              <Grid item style={{ margin: 'auto 1em' }}>
                {`${props.integration.name} settings`}
              </Grid>
            </Grid>
          </div>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <form noValidate autoComplete="off">
          {
            integration.fields.map(field => {
              var TagName = field.control
              return <TagName
                key={`integration-${field.id}`}
                integration={integration}
                label={field.name}
                variant="outlined"
                record={record}
                field={field}
              />
            })
          }
          </form>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button onClick={() => { setShowDialog(false) }}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default QuickSettingsButton
