import ExtensionIcon from '@material-ui/icons/Extension'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import FaceIcon from '@material-ui/icons/Face'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import SettingsIcon from '@material-ui/icons/Settings'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'

const palette = [
  'rgba(0,0,0,0.05)',
  'rgba(0,0,0,0.1)',
  'rgba(0,0,0,0.15)',
  'rgba(0,0,0,0.20)',
  'rgba(0,0,0,0.25)',
  'rgba(0,0,0,0.30)',
  'rgba(0,0,0,0.35)',
  'rgba(0,0,0,0.40)',
  'rgba(0,0,0,0.45)',
  'rgba(0,0,0,0.50)',
]
const [bg, fg] = [ 2, 1 ]

const dashboardItems = [
  {
    title: "Account",
    icon: AlternateEmailIcon,
    toolicon: SettingsIcon,
    text: "Manage account details and payment methods.",
    to: { pathname: '/account' },
    acl: 'account.view',
    style: {
      color: palette[fg],
      backgroundColor: palette[bg],
    },
    rows: 1,
    cols: 2
  },
  {
    title: "Users",
    icon: FaceIcon,
    toolicon: SettingsIcon,
    text: "Manage users and permissions.",
    to: { pathname: "/user" },
    acl: 'user.view',
    style: {
      color: palette[fg+1],
      backgroundColor: palette[bg+1],
    },
    rows: 1,
    cols: 3
  },
  {
    title: "Integrations",
    icon: ExtensionIcon,
    toolicon: SettingsIcon,
    text: "Configure integrations with external services.",
    to: { pathname: "/integration" },
    acl: 'integrations.view',
    style: {
      color: palette[fg+3],
      backgroundColor: palette[bg+3],
    },
    rows: 1,
    cols: 3
  },
  {
    title: "Documentation",
    icon: LocalLibraryIcon,
    toolicon: HelpTwoToneIcon,
    text: "Documentation and examples.",
    to: {
      pathname: "https://docs.tac.dog/",
      target: "_blank"
    },
    style: {
      color: palette[fg+2],
      backgroundColor: palette[bg+2],
    },
    rows: 1,
    cols: 2
  },
]

export default dashboardItems
