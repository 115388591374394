import React from 'react'
import { withAuthenticator } from 'aws-amplify-react'
import Center from 'react-center'

// material-ui components and functions
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { orange, lightBlue, grey } from '@material-ui/core/colors'

// layouts and components
import Layout from './layouts'
import AppBar from './components/AppBar'

// styles
import 'typeface-roboto'
import '@aws-amplify/ui/dist/style.css'

import './App.css'

const authenticator_settings = {
  signUpConfig: {
    header: "tac.dog console",
    hiddenDefaults: ["phone_number", "email"],
    signUpFields: [{
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'email',
      custom: false
    }, {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
      custom: false
    } //, {
    //   label: "Account name",
    //   key: "account_name",
    //   required: true,
    //   displayOrder: 3,
    //   type: "string"
    // }
  ]
  }
}

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: grey,
    secondary: lightBlue,
    text: {
      primary: grey[900],
      secondary: grey[600]
    }
  },
  status: {
    danger: orange,
  },
})

const useStyles = makeStyles((theme) => ({}))

const App = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar />
        <Center className={classes.content}>
          <Layout />
        </Center>
      </div>
    </ThemeProvider>
  )
}

export default withAuthenticator(App, authenticator_settings)
